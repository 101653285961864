<template>
  <div id="about">
    <info-company :model="Model.InfoCompany" :first-load="firstLoad" />
    <formation-and-development :model="Model.FormationAndDevelopment" />
    <vision :model="Model.Vision" />
    <core-values />
    <executive-board :model="Model.ExecutiveBoard" />
    <agent-system :model="Model.AgentSystem" />
  </div>
</template>

<script>
// @ is an alias to /src

import InfoCompany from "@/views/About/components/InfoCompany";
import FormationAndDevelopment from "@/views/About/components/FormationAndDevelopment";
import Vision from "@/views/About/components/Vision";
import CoreValues from "@/views/About/components/CoreValues";
import ExecutiveBoard from "@/views/About/components/ExecutiveBoard";
import OrganizationalStructure from "./components/OrganizationalStructure.vue";
import AgentSystem from "@/views/About/components/AgentSystem";
import axios from "axios";
import { apiUrl } from "@/main";

export default {
  name: "About",
  components: {
    AgentSystem,
    ExecutiveBoard,
    CoreValues,
    Vision,
    FormationAndDevelopment,
    InfoCompany,
    OrganizationalStructure
  },
  data: () => ({
    Model: {
      InfoCompany: {},
      FormationAndDevelopment: {},
      Vision: [],
      CoreValues: [],
      ExecutiveBoard: [],
      OrganizationalStructure: [],
      AgentSystem: {}
    },
    firstLoad: true,
    Info: {
      meta_title: 'CÔNG TY TNHH ĐẦU TƯ MAI TÂM ANH | Về Chúng Tôi'
    }
  }),
  mounted() {
    axios
      .get(`${apiUrl.page}/ve-chung-toi`)
      .then(response => {
        if (!response.data) return false;
        let Model = response.data[0].acf;
        this.Model.AgentSystem = Model.agency_system;
        this.Model.InfoCompany = Model.information;
        this.Model.FormationAndDevelopment = Model.formation_and_development;
        this.Model.ExecutiveBoard = Model.executive_board;
        this.Model.Vision = Model.motto_of_operation;
        this.firstLoad = false;
      })
      .catch(error => {
        console.log(error);
      });
    axios
        .get(`${apiUrl.api}/acf/v3/options/options`)
        .then(res => {
          if (!res.data) return false;
          this.Info = res.data.acf;
          this.firstLoad = false;
        })
        .catch(err => {
          console.log(err);
        });
    this.firstLoad = true;
  },
  metaInfo() {
    return {
      title: this.Info.meta_title  + ' | Về Chúng Tôi',
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title + ' | Về Chúng Tôi'
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  }
};
</script>
